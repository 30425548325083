<template>
    <div>
        <div class="bg-white p-24">
            <div class="d-flex justify-content-between align-items-top">
                <div>
                    <div class="main-text">{{this.$route.params.invoice}}</div>
                    <p class="text-simple">You have to pay {{this.$route.query.type=='initial'?'25': 'remaining 75'}}% of the total amount ({{ Number(this.$route.query.amount) | currency }}) in order to get your document signed.</p>
                    <p class="small-text">{{getFormatedDate('MMMM, DD YYYY')}}</p>
                </div>
                <div>
                    <div ><span class="sup text-primary">$</span><span class="price-text text-primary">{{this.$route.query.type=='initial'?(Number(this.$route.query.amount)/4).toFixed(2):(this.$route.query.amount/4*3).toFixed(2)}}</span><span class="small-text"></span></div>
                </div>
            </div>
            <div class="border-top mt-1 py-1">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <div class="main-text">Amount Due</div>
                    </div>
                    <div>
                        <div><span class="sup text-primary">$</span><span class="price-text text-primary">{{this.$route.query.type=='initial'?(Number(this.$route.query.amount)/4).toFixed(2):(this.$route.query.amount/4*3).toFixed(2)}}</span></div>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <app-simple-button variant="outline-primary" class="mr-1" @click="$emit('prev')">Back</app-simple-button>
                <app-simple-button variant="primary" @click="$emit('next')">Confirm</app-simple-button>
            </div>
        </div>
    </div>

</template>
<script>
import {BBadge} from 'bootstrap-vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
import AppHeading3 from "@/components/app-components/AppHeading3.vue"
import moment from 'moment'
export default{
    components:{
        BBadge,
        AppSimpleButton,
        AppHeading3
    },
    data(){
        return{
            //
        }
    },
    methods: {
        getFormatedDate(format){
            return moment().format(format)
        },
    },
}
</script>
<style scoped>
.p-24{
    padding: 24px;
    border-radius: 6px;
}
.sup{
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    position: relative;
    bottom: 15px;
}
.price-text{
    font-size: 36px;
    font-weight: 600;
    line-height: 52px;
}
.text-simple{
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #475569;
}
.main-text{
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: #0F172A;
}
.small-text{
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #A3AAB4;
}
</style>